@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
   url("./utils/fonts/Montserrat.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "PTSerif";
  src: local("PTSerif"),
   url("./utils/fonts/PTSerif.ttf") format("truetype");
  font-weight: bold;
}

@keyframes bgColor {
  0% {
    background-color: rgba(179, 0, 255, 0.2);
    box-shadow: -1px -1px 4px rgba(179, 0, 255, 0.2);
  }
  12.5% {
    background-color: rgba(255, 132, 0, 0.26);
    box-shadow: -1px -1px 4px rgba(255, 132, 0, 0.2);
  }
  25% {
    background-color: rgba(255, 132, 0, 0.26);
    box-shadow: -1px -1px 4px rgba(255, 132, 0, 0.2);
  }
  37.5% {
    background-color: rgba(255, 251, 0, 0.26);
    box-shadow: -1px -1px 4px rgba(255, 251, 0, 0.2);
  }
  50% {
    background-color: rgba(13, 255, 0, 0.26);
    box-shadow: -1px -1px 4px rgba(13, 255, 0, 0.2);
  }
  62.5% {
    background-color: rgba(0, 89, 255, 0.26);
    box-shadow: -1px -1px 4px rgba(0, 89, 255, 0.2);
  }
  75% {
    background-color: rgba(255, 0, 242, 0.2);
    box-shadow: -1px -1px 4px rgba(255, 0, 242, 0.2);
  }
  87.5% {
    background-color: rgba(255, 0, 242, 0.2);
    box-shadow: -1px -1px 4px rgba(255, 0, 242, 0.2);
  }
  100% {
    background-color: rgba(179, 0, 255, 0.2);
    box-shadow: -1px -1px 4px rgba(179, 0, 255, 0.2);
  }
}

body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div {
  box-sizing: border-box;
}

.MuiTypography-root {
  font-family: "Montserrat !important";
}
